import { CUSTOMER_SUB_PORTALS } from "common/constants";

/* eslint-disable indent */
export const JClickHandler =
  (step, stages, dispatch, toggleCurrentModal, subPortal, portal) => () => {
    if (!stages?.loaded) {
      // eslint-disable-next-line no-console
      console.error("Stages not loaded yet.");
      return;
    }
    if (subPortal === "INVESTOR" || subPortal === "SP") {
      // add subPortal to start of step
      step = `${subPortal}_${step}`;
    }
    switch (step) {
      case "IbJourney":
        if (!stages.individual.submitProfile) {
          dispatch(toggleCurrentModal("SubmitIndProfile"));
        } else if (!stages.ib.ibQuestionnaire) {
          dispatch(toggleCurrentModal("IBQuestionnaire"));
        } else if (!stages.kycUpload) {
          dispatch(toggleCurrentModal("UploadKycModal"));
        } else if (!stages.kycApproved && stages.kycUpload) {
          dispatch(toggleCurrentModal("KYCProgress"));
        }
        break;
      case "goldJourney":
      case "kycApproved":
      case "openAccount":
      case "Transfer":
      case "selectDepositMethodModal":
      case "fiatDeposit":
      case "fiatWithdraw":
      case "mt5Deposit":
      case "selectWithdrawalMethodModal":
        if (!stages.individual.submitProfile) {
          dispatch(toggleCurrentModal("SubmitIndProfile"));
        }
         else if (!stages.openAccount) {
          dispatch(
            toggleCurrentModal(
              subPortal === CUSTOMER_SUB_PORTALS.SP &&
                !stages.sp.configureProfile
                ? "NewMamProfile"
                : subPortal === CUSTOMER_SUB_PORTALS.INVESTOR &&
                  !stages?.investor?.becomeInvestor
                ? "CreateInvestorAccountModal"
                : "JourneyCreateAccount"
            )
          );
        } else if (!stages.kycUpload) {
          dispatch(toggleCurrentModal("UploadKycModal"));
        } else if (!stages.kycApproved && stages.kycUpload) {
          dispatch(toggleCurrentModal("KYCProgress"));
        } else {
          dispatch(toggleCurrentModal(step));
        }
        break;
      case "requestPartnership":
      case "requestInvestor":
        if (!stages.kycUpload) {
          dispatch(toggleCurrentModal("UploadKycModal"));
        } else if (!stages.kycApproved && stages.kycUpload) {
          dispatch(toggleCurrentModal("KYCProgress"));
        } else {
          if (step === "requestPartnership") {
            window.location.href = "/request-partnership";
          } else {
            window.location.href = "/request-investor";
          }
        }
        break;
      case "startTrading":
        if (!stages.individual.submitProfile) {
          dispatch(toggleCurrentModal("SubmitIndProfile"));
        } else if (!stages.openAccount) {
          dispatch(toggleCurrentModal("JourneyCreateAccount"));
        } else if (!stages.kycUpload) {
          dispatch(toggleCurrentModal("UploadKycModal"));
        } else if (!stages.kycApproved && stages.kycUpload) {
          dispatch(toggleCurrentModal("KYCProgress"));
        } else if (!stages.startTrading) window.location.href = "/platforms";
        break;
      case "investorJourney":
        if (!stages.individual.submitProfile) {
          dispatch(toggleCurrentModal("SubmitIndProfile"));
        } else if (!stages.investor?.openAccount) {
          dispatch(toggleCurrentModal("CreateInvestorAccountModal"));
        } else if (!stages.kycUpload) {
          dispatch(toggleCurrentModal("UploadKycModal"));
        } else if (!stages.kycApproved && stages.kycUpload) {
          dispatch(toggleCurrentModal("KYCProgress"));
        } else if (!stages.madeDeposit) {
          dispatch(toggleCurrentModal("selectDepositMethodModal"));
        } else if (!stages.investor.linkAccount && stages.investor.requestLink) {
          return dispatch(toggleCurrentModal("LinkProgress"));
        } else if (!stages.investor.linkAccount) {
          dispatch(toggleCurrentModal("LinkMasterModal"));
        } else {
          const st = step.split("_")[1];
          dispatch(toggleCurrentModal(st));
        }
        break;
      case "INVESTOR_LinkMasterModal":
      case "INVESTOR_UnlinkMasterModal":
      case "INVESTOR_CreateInvestorAccountModal":
      case "INVESTOR_selectDepositMethodModal":
      case "INVESTOR_selectWithdrawalMethodModal":
      case "INVESTOR_Transfer":
      case "INVESTOR_Invest":
        if (!stages.individual.submitProfile) {
          dispatch(toggleCurrentModal("SubmitIndProfile"));
        } else if (!stages.investor?.openAccount || step === "INVESTOR_CreateInvestorAccountModal") {
          dispatch(toggleCurrentModal("CreateInvestorAccountModal"));
        } else if (!stages.kycUpload) {
          dispatch(toggleCurrentModal("UploadKycModal"));
        } else if (!stages.kycApproved && stages.kycUpload) {
          dispatch(toggleCurrentModal("KYCProgress"));
        } else if (!stages.madeDeposit) {
          dispatch(toggleCurrentModal("selectDepositMethodModal"));
        } else {
          const st = step.split("_")[1];
          dispatch(toggleCurrentModal(st));
        }
        break;
      case "spJourney":
        if (!stages.individual.submitProfile) {
          dispatch(toggleCurrentModal("SubmitIndProfile"));
        } else if (!stages.kycUpload) {
          dispatch(toggleCurrentModal("UploadKycModal"));
        }  else if (!stages.kycApproved && stages.kycUpload) {
          dispatch(toggleCurrentModal("KYCProgress"));
        } else if (!stages.sp.requestProfile && !stages.sp.configureProfile) {
          dispatch(toggleCurrentModal("NewMamProfile"));
        } else if (stages.sp.requestProfile && !stages.sp.configureProfile) {
          dispatch(toggleCurrentModal("ProfileRequest"));
        } else if (!stages.startTrading) {
          dispatch(toggleCurrentModal("StartTradingModal"));
        }
        break;
      case "SP_selectDepositMethodModal":
      case "SP_selectWithdrawalMethodModal":
      case "SP_Transfer":
      case "SP_NewMamProfile":
      case "SP_ProfileRequest":
        if (!stages.individual.submitProfile) {
          dispatch(toggleCurrentModal("SubmitIndProfile"));
        } else if (!stages.kycUpload) {
          dispatch(toggleCurrentModal("UploadKycModal"));
        } else if (!stages.kycApproved && stages.kycUpload) {
          dispatch(toggleCurrentModal("KYCProgress"));
        } else if (!stages.sp.requestProfile && !stages.sp.configureProfile) {
          dispatch(toggleCurrentModal("NewMamProfile"));
        } else if (stages.sp.requestProfile && !stages.sp.configureProfile) {
          dispatch(toggleCurrentModal("ProfileRequest"));
        } else {
          const st = step.split("_")[1];
          dispatch(toggleCurrentModal(st));
        }
        break;
      default:
        break;
    }
  };
